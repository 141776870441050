<template>
  <div>
    <Card dis-hover style="text-align: left">
      <Form class="searchForm" :model="searchFormData" ref="formInline" label-position="right" inline>
        <FormItem label="名 称" prop="name" :label-width="60">
          <Input v-model="searchFormData.name" type="text" placeholder="name"></Input>
        </FormItem>
        <FormItem label="URL" prop="url" :label-width="60">
          <Input v-model="searchFormData.url" type="text" placeholder="url"></Input>
        </FormItem>
        <FormItem label="状态" prop="enabled" :label-width="60">
            <Select v-model="searchFormData.enabled" style="width: 100px">
              <Option :value="-1">全部</Option>
              <Option :value="0">禁用</Option>
              <Option :value="1">开启</Option>
            </Select>
        </FormItem>
        <FormItem>
          <Button type="primary" style="margin-right: 8px" icon="ios-search" @click="search">查 询</Button>
          <Button type="primary" icon="md-add" @click="create">添 加</Button>
        </FormItem>
      </Form>
    </Card>
    <Table height="700" :columns="columns" :data="datas">
      <template slot-scope="{ row }" slot="action">
        <Button type="primary" size="small" @click="handleEdit(row)" style="margin-right: 5px" ghost>编 辑</Button>
        <Button type="error" size="small" @click="handleDel(row)" style="margin-right: 5px" ghost>删 除</Button>
      </template>
    </Table>
    <base-drawer :is-show="isShow" :title="title" @closeDrawer="closeDrawer" @submit="submit">
      <Form :model="formData">
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="任务名" label-position="top">
              <Input v-model="formData.name" placeholder="任务名" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="URL" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="top-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:1px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>指定任务测试的目标URL</p>
                  <p style="color: red">(不能包含空格,且必须以http://或者https://开头)</p>
                </div>
              </el-tooltip>
              <Input v-model="formData.url" @input="CheckUrl" :class="{ 'invalid-url': !isValidUrl}" placeholder="URL"/>
              <div v-if="!isValidUrl" class="error-message">无效的URL</div>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="监测频率" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="top-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:1px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>控制节点执行任务的频率</p>
                </div>
              </el-tooltip>
              <Select v-model="formData.interval" placeholder="监测频率">
                <Option :value="3">3秒</Option>
                <Option :value="5">5秒</Option>
                <Option :value="10">10秒</Option>
                <Option :value="30">30秒</Option>
                <Option :value="60">1分钟</Option>
                <Option :value="300">5分钟</Option>
                <Option :value="600">10分钟</Option>
                <Option :value="1800">30分钟</Option>
                <Option :value="1440">1小时</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="监测大小" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="top-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:1px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>http探测请求的消息体大小,单位为KB.请根据下载大小设置合理的超时时间,以免结果出现误差</p>
                </div>
              </el-tooltip>
              <Input v-model.number="formData.sizeDownload" placeholder="监测大小">
                <span slot="append">KB</span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="连接超时" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="top-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:1px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>限制建立连接的超时时间,单位为秒(s)</p>
                </div>
              </el-tooltip>
              <Input v-model.number="formData.connectTimeout" placeholder="连接超时">
                <span slot="append">秒</span>
              </Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="总超时" label-position="top">
              <el-tooltip class="icon-item" effect="light" content="整个请求的超时时间,单位为秒(s)" placement="top-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:1px;'"> </i>
              </el-tooltip>
              <Input v-model.number="formData.totalTimeout" placeholder="总超时">
                <span slot="append">秒</span>
              </Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="域名解析地址" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="top-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:1px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>指定请求中所有域名解析所使用的域名服务器地址</p>
                </div>
              </el-tooltip>
              <Input v-model="formData.resolveAddr" placeholder="域名解析地址"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="期望返回码" label-position="top">
              <el-tooltip class="icon-item" effect="light" content="" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>期望连接返回的请求码,使用逗号分隔.</p>
                  <p style="color: red;">示例:206,200</p>
                </div>
              </el-tooltip>
              <Input v-model="formData.expectCode" placeholder="期望返回码"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="任务最大节点数" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>参与测试最多节点数的限制,指定后会在各个区域均匀调度节点来执行测试任务</p>
                  <p style="color: red;">(该限制不包括特殊指定的节点且只有省份运营商节点数配置为0时才会生效)</p>
                </div>
              </el-tooltip>
              <Input v-model.number="formData.maxNodes" placeholder="任务最大节点数"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="省份运营商节点数" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>以省份和运营商组合为一个区域,分配测试节点,比如湖南移动,湖南电信都是不同的组合,都会分配该配置值的节点数.</p>
                  <p style="color: red;">(该配置不为0时,任务最大节点数配置无效)</p>
                </div>
              </el-tooltip>
              <Input v-model.number="formData.perNodes" placeholder="省份运营商节点数"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="任务执行次数" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>限制任务执行的次数.</p>
                  <p style="color: red;">(0表示无限制执行)</p>
                </div>
              </el-tooltip>
              <Input v-model.number="formData.execTimes" placeholder="执行次数,0表示任务一直执行,非0执行有限次数"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="网络类型筛选" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>不区分类型：会随机调度专线或者拨号的节点执行任务</p>
                  <p>专线：只会调度专线类型的节点执行任务</p>
                  <p>拨号：只会调度拨号上网类型的节点执行任务</p>
                </div>
              </el-tooltip>
              <Select v-model="formData.lineType"  placeholder="网络类型筛选">
                <Option :value="0">不区分类型</Option>
                <Option :value="1">专线</Option>
                <Option :value="2">拨号</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="指定节点" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>特别指定执行拨测任务的节点,每行一个节点ID</p>
                </div>
              </el-tooltip>
              <Input type="textarea" v-model="formData.specNodeList" :rows="4" placeholder="" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="IP类型" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>不区分ip类型：测试时,会随机使用本机的Ipv6地址或者ipv4地址进行测试</p>
                  <p>IPV4：测试时,只会使用本机的ipv4地址进行测试</p>
                  <p>IPV6：调度只会选择有IPV6地址的节点,测试时,只会使用本机的ipv6地址进行测试</p>
                </div>
              </el-tooltip>
              <Select v-model="formData.ipType" >
                <Option :value="2">不区分ip类型</Option>
                <Option :value="0">IPV4</Option>
                <Option :value="1">IPV6</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="32">
          <Col span="12">
            <FormItem label="自定义Host" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>(URL主机名需替换为 ${host} 变量, 例如: https://${host}/test.txt)</p>
                </div>
              </el-tooltip>
              <Input v-model="formData.host" type="textarea" :rows="4" placeholder="每行一个IP" />
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="自定义请求头" label-position="top">
              <el-tooltip class="icon-item" effect="light" placement="left-start">
                <i class= 'el-icon-warning' style="color:#409eff; margin-left:5px;'"> </i>
                <div style="width: 200px;" slot="content">
                  <p>指定任务执行时使用指定的请求头</p>
                  <p>每行对应一个请求头配置,key和value中间用冒号(:)分隔  key:value</p>
                  <p>例如: User-agent: curl</p>
                </div>
              </el-tooltip>
              <Input v-model="formData.requestHeader" type="textarea" :rows="4" placeholder="每行一个字段,键和值冒号分隔例如User-agent: curl" />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="12">
            <FormItem label="状 态" label-position="top">
              <i-switch size="large" v-model="formData.enabled" :true-value="1" :false-value="0" true-color="#19be6b" false-color="#ed4014">
                <span slot="open">开启</span>
                <span slot="close">暂停</span>
              </i-switch>
            </FormItem>
          </Col>
        </Row>
        <Divider orientation="left">监测点</Divider>
        <Row>
          <Form :model="moniSrc" :label-width="60">
            <Col span="24">
              <FormItem label="区域" prop="region">
                <Select v-model="moniSrc.region" style="width: 600px" filterable allow-create>
                  <Option value="全部">全部</Option>
                  <Option value="db">东北</Option>
                  <Option value="hb">华北</Option>
                  <Option value="hz">华中</Option>
                  <Option value="hd">华东</Option>
                  <Option value="hn">华南</Option>
                  <Option value="xb">西北</Option>
                  <Option value="xn">西南</Option>
                </Select>
              </FormItem>
              <FormItem label="省份" prop="province">
                <Select v-model="moniSrc.province" style="width: 600px"  multiple filterable allow-create>
                  <Option value="全部">全部</Option>
                  <Option v-for="v in getProvince" :value="v.name" :key="v._index">{{v.name}}</Option>
                </Select>
              </FormItem>
              <FormItem label="运营商" prop="isp">
                <Select v-model="moniSrc.isp" style="width: 600px" filterable multiple allow-create>
                  <Option value="全部">全部</Option>
                  <Option value="中国电信">电信</Option>
                  <Option value="中国联通">联通</Option>
                  <Option value="中国移动">移动</Option>
                </Select>
              </FormItem>
<!--              <FormItem label="样本数" prop="hosts">-->
<!--                <Select v-model="moniSrc.sampleSize" style="width: 600px" filterable allow-create>-->
<!--                  <Option :value="1">1</Option>-->
<!--                  <Option :value="2">2</Option>-->
<!--                  <Option :value="3">3</Option>-->
<!--                </Select>-->
<!--              </FormItem>-->
              <FormItem label="设备ID" label-position="top">
                <Input type="textarea" v-model="moniSrc.moniHost" :rows="4" placeholder="每行一个ID" />
              </FormItem>
              <Button type="primary" icon="md-add" @click="handleAddMoniSrc" long ghost>添 加</Button>
            </Col>
          </Form>
        </Row>
        <Divider orientation="left">监测信息</Divider>
        <Row>
          <Col span="24">
            <Table :columns="moniSrcColumns" :data="formData.monitorDetail">
              <template slot-scope="{ row }" slot="action">
                <Button type="error" size="small" @click="handleRemoveMoniSrc(row)" style="margin-right: 5px" ghost>删 除</Button>
              </template>
            </Table>
          </Col>
        </Row>
        <FormItem label="任务描述" label-position="top">
          <Input type="textarea" v-model="formData.description" :rows="4" placeholder="please enter the description" />
        </FormItem>
      </Form>
    </base-drawer>
  </div>
</template>

<script>
import tableExpand from "@/View/monitor/httpDetectTableExpand";
import baseDrawer from "@/components/base/baseDrawer";
import {del, get, post, put} from "@/api/http";
import Notice from "@/components/baseNotice";

//https://raw.githubusercontent.com/XinZiIng/multi-level-address/master/second-level-address.json
const GEO = [
  {
    code: "11",
    name: "北京市",
    children: [
      {
        code: "110101",
        name: "东城区",
      },
      {
        code: "110102",
        name: "西城区",
      },
      {
        code: "110105",
        name: "朝阳区",
      },
      {
        code: "110106",
        name: "丰台区",
      },
      {
        code: "110107",
        name: "石景山区",
      },
      {
        code: "110108",
        name: "海淀区",
      },
      {
        code: "110109",
        name: "门头沟区",
      },
      {
        code: "110111",
        name: "房山区",
      },
      {
        code: "110112",
        name: "通州区",
      },
      {
        code: "110113",
        name: "顺义区",
      },
      {
        code: "110114",
        name: "昌平区",
      },
      {
        code: "110115",
        name: "大兴区",
      },
      {
        code: "110116",
        name: "怀柔区",
      },
      {
        code: "110117",
        name: "平谷区",
      },
      {
        code: "110118",
        name: "密云区",
      },
      {
        code: "110119",
        name: "延庆区",
      },
    ],
  },
  {
    code: "12",
    name: "天津市",
    children: [
      {
        code: "120101",
        name: "和平区",
      },
      {
        code: "120102",
        name: "河东区",
      },
      {
        code: "120103",
        name: "河西区",
      },
      {
        code: "120104",
        name: "南开区",
      },
      {
        code: "120105",
        name: "河北区",
      },
      {
        code: "120106",
        name: "红桥区",
      },
      {
        code: "120110",
        name: "东丽区",
      },
      {
        code: "120111",
        name: "西青区",
      },
      {
        code: "120112",
        name: "津南区",
      },
      {
        code: "120113",
        name: "北辰区",
      },
      {
        code: "120114",
        name: "武清区",
      },
      {
        code: "120115",
        name: "宝坻区",
      },
      {
        code: "120116",
        name: "滨海新区",
      },
      {
        code: "120117",
        name: "宁河区",
      },
      {
        code: "120118",
        name: "静海区",
      },
      {
        code: "120119",
        name: "蓟州区",
      },
    ],
  },
  {
    code: "13",
    name: "河北省",
    children: [
      {
        code: "1301",
        name: "石家庄市",
      },
      {
        code: "1302",
        name: "唐山市",
      },
      {
        code: "1303",
        name: "秦皇岛市",
      },
      {
        code: "1304",
        name: "邯郸市",
      },
      {
        code: "1305",
        name: "邢台市",
      },
      {
        code: "1306",
        name: "保定市",
      },
      {
        code: "1307",
        name: "张家口市",
      },
      {
        code: "1308",
        name: "承德市",
      },
      {
        code: "1309",
        name: "沧州市",
      },
      {
        code: "1310",
        name: "廊坊市",
      },
      {
        code: "1311",
        name: "衡水市",
      },
    ],
  },
  {
    code: "14",
    name: "山西省",
    children: [
      {
        code: "1401",
        name: "太原市",
      },
      {
        code: "1402",
        name: "大同市",
      },
      {
        code: "1403",
        name: "阳泉市",
      },
      {
        code: "1404",
        name: "长治市",
      },
      {
        code: "1405",
        name: "晋城市",
      },
      {
        code: "1406",
        name: "朔州市",
      },
      {
        code: "1407",
        name: "晋中市",
      },
      {
        code: "1408",
        name: "运城市",
      },
      {
        code: "1409",
        name: "忻州市",
      },
      {
        code: "1410",
        name: "临汾市",
      },
      {
        code: "1411",
        name: "吕梁市",
      },
    ],
  },
  {
    code: "15",
    name: "内蒙古自治区",
    children: [
      {
        code: "1501",
        name: "呼和浩特市",
      },
      {
        code: "1502",
        name: "包头市",
      },
      {
        code: "1503",
        name: "乌海市",
      },
      {
        code: "1504",
        name: "赤峰市",
      },
      {
        code: "1505",
        name: "通辽市",
      },
      {
        code: "1506",
        name: "鄂尔多斯市",
      },
      {
        code: "1507",
        name: "呼伦贝尔市",
      },
      {
        code: "1508",
        name: "巴彦淖尔市",
      },
      {
        code: "1509",
        name: "乌兰察布市",
      },
      {
        code: "1522",
        name: "兴安盟",
      },
      {
        code: "1525",
        name: "锡林郭勒盟",
      },
      {
        code: "1529",
        name: "阿拉善盟",
      },
    ],
  },
  {
    code: "21",
    name: "辽宁省",
    children: [
      {
        code: "2101",
        name: "沈阳市",
      },
      {
        code: "2102",
        name: "大连市",
      },
      {
        code: "2103",
        name: "鞍山市",
      },
      {
        code: "2104",
        name: "抚顺市",
      },
      {
        code: "2105",
        name: "本溪市",
      },
      {
        code: "2106",
        name: "丹东市",
      },
      {
        code: "2107",
        name: "锦州市",
      },
      {
        code: "2108",
        name: "营口市",
      },
      {
        code: "2109",
        name: "阜新市",
      },
      {
        code: "2110",
        name: "辽阳市",
      },
      {
        code: "2111",
        name: "盘锦市",
      },
      {
        code: "2112",
        name: "铁岭市",
      },
      {
        code: "2113",
        name: "朝阳市",
      },
      {
        code: "2114",
        name: "葫芦岛市",
      },
    ],
  },
  {
    code: "22",
    name: "吉林省",
    children: [
      {
        code: "2201",
        name: "长春市",
      },
      {
        code: "2202",
        name: "吉林市",
      },
      {
        code: "2203",
        name: "四平市",
      },
      {
        code: "2204",
        name: "辽源市",
      },
      {
        code: "2205",
        name: "通化市",
      },
      {
        code: "2206",
        name: "白山市",
      },
      {
        code: "2207",
        name: "松原市",
      },
      {
        code: "2208",
        name: "白城市",
      },
      {
        code: "2224",
        name: "延边朝鲜族自治州",
      },
    ],
  },
  {
    code: "23",
    name: "黑龙江省",
    children: [
      {
        code: "2301",
        name: "哈尔滨市",
      },
      {
        code: "2302",
        name: "齐齐哈尔市",
      },
      {
        code: "2303",
        name: "鸡西市",
      },
      {
        code: "2304",
        name: "鹤岗市",
      },
      {
        code: "2305",
        name: "双鸭山市",
      },
      {
        code: "2306",
        name: "大庆市",
      },
      {
        code: "2307",
        name: "伊春市",
      },
      {
        code: "2308",
        name: "佳木斯市",
      },
      {
        code: "2309",
        name: "七台河市",
      },
      {
        code: "2310",
        name: "牡丹江市",
      },
      {
        code: "2311",
        name: "黑河市",
      },
      {
        code: "2312",
        name: "绥化市",
      },
      {
        code: "2327",
        name: "大兴安岭地区",
      },
    ],
  },
  {
    code: "31",
    name: "上海市",
    children: [
      {
        code: "310101",
        name: "黄浦区",
      },
      {
        code: "310104",
        name: "徐汇区",
      },
      {
        code: "310105",
        name: "长宁区",
      },
      {
        code: "310106",
        name: "静安区",
      },
      {
        code: "310107",
        name: "普陀区",
      },
      {
        code: "310109",
        name: "虹口区",
      },
      {
        code: "310110",
        name: "杨浦区",
      },
      {
        code: "310112",
        name: "闵行区",
      },
      {
        code: "310113",
        name: "宝山区",
      },
      {
        code: "310114",
        name: "嘉定区",
      },
      {
        code: "310115",
        name: "浦东新区",
      },
      {
        code: "310116",
        name: "金山区",
      },
      {
        code: "310117",
        name: "松江区",
      },
      {
        code: "310118",
        name: "青浦区",
      },
      {
        code: "310120",
        name: "奉贤区",
      },
      {
        code: "310151",
        name: "崇明区",
      },
    ],
  },
  {
    code: "32",
    name: "江苏省",
    children: [
      {
        code: "3201",
        name: "南京市",
      },
      {
        code: "3202",
        name: "无锡市",
      },
      {
        code: "3203",
        name: "徐州市",
      },
      {
        code: "3204",
        name: "常州市",
      },
      {
        code: "3205",
        name: "苏州市",
      },
      {
        code: "3206",
        name: "南通市",
      },
      {
        code: "3207",
        name: "连云港市",
      },
      {
        code: "3208",
        name: "淮安市",
      },
      {
        code: "3209",
        name: "盐城市",
      },
      {
        code: "3210",
        name: "扬州市",
      },
      {
        code: "3211",
        name: "镇江市",
      },
      {
        code: "3212",
        name: "泰州市",
      },
      {
        code: "3213",
        name: "宿迁市",
      },
    ],
  },
  {
    code: "33",
    name: "浙江省",
    children: [
      {
        code: "3301",
        name: "杭州市",
      },
      {
        code: "3302",
        name: "宁波市",
      },
      {
        code: "3303",
        name: "温州市",
      },
      {
        code: "3304",
        name: "嘉兴市",
      },
      {
        code: "3305",
        name: "湖州市",
      },
      {
        code: "3306",
        name: "绍兴市",
      },
      {
        code: "3307",
        name: "金华市",
      },
      {
        code: "3308",
        name: "衢州市",
      },
      {
        code: "3309",
        name: "舟山市",
      },
      {
        code: "3310",
        name: "台州市",
      },
      {
        code: "3311",
        name: "丽水市",
      },
    ],
  },
  {
    code: "34",
    name: "安徽省",
    children: [
      {
        code: "3401",
        name: "合肥市",
      },
      {
        code: "3402",
        name: "芜湖市",
      },
      {
        code: "3403",
        name: "蚌埠市",
      },
      {
        code: "3404",
        name: "淮南市",
      },
      {
        code: "3405",
        name: "马鞍山市",
      },
      {
        code: "3406",
        name: "淮北市",
      },
      {
        code: "3407",
        name: "铜陵市",
      },
      {
        code: "3408",
        name: "安庆市",
      },
      {
        code: "3410",
        name: "黄山市",
      },
      {
        code: "3411",
        name: "滁州市",
      },
      {
        code: "3412",
        name: "阜阳市",
      },
      {
        code: "3413",
        name: "宿州市",
      },
      {
        code: "3415",
        name: "六安市",
      },
      {
        code: "3416",
        name: "亳州市",
      },
      {
        code: "3417",
        name: "池州市",
      },
      {
        code: "3418",
        name: "宣城市",
      },
    ],
  },
  {
    code: "35",
    name: "福建省",
    children: [
      {
        code: "3501",
        name: "福州市",
      },
      {
        code: "3502",
        name: "厦门市",
      },
      {
        code: "3503",
        name: "莆田市",
      },
      {
        code: "3504",
        name: "三明市",
      },
      {
        code: "3505",
        name: "泉州市",
      },
      {
        code: "3506",
        name: "漳州市",
      },
      {
        code: "3507",
        name: "南平市",
      },
      {
        code: "3508",
        name: "龙岩市",
      },
      {
        code: "3509",
        name: "宁德市",
      },
    ],
  },
  {
    code: "36",
    name: "江西省",
    children: [
      {
        code: "3601",
        name: "南昌市",
      },
      {
        code: "3602",
        name: "景德镇市",
      },
      {
        code: "3603",
        name: "萍乡市",
      },
      {
        code: "3604",
        name: "九江市",
      },
      {
        code: "3605",
        name: "新余市",
      },
      {
        code: "3606",
        name: "鹰潭市",
      },
      {
        code: "3607",
        name: "赣州市",
      },
      {
        code: "3608",
        name: "吉安市",
      },
      {
        code: "3609",
        name: "宜春市",
      },
      {
        code: "3610",
        name: "抚州市",
      },
      {
        code: "3611",
        name: "上饶市",
      },
    ],
  },
  {
    code: "37",
    name: "山东省",
    children: [
      {
        code: "3701",
        name: "济南市",
      },
      {
        code: "3702",
        name: "青岛市",
      },
      {
        code: "3703",
        name: "淄博市",
      },
      {
        code: "3704",
        name: "枣庄市",
      },
      {
        code: "3705",
        name: "东营市",
      },
      {
        code: "3706",
        name: "烟台市",
      },
      {
        code: "3707",
        name: "潍坊市",
      },
      {
        code: "3708",
        name: "济宁市",
      },
      {
        code: "3709",
        name: "泰安市",
      },
      {
        code: "3710",
        name: "威海市",
      },
      {
        code: "3711",
        name: "日照市",
      },
      {
        code: "3712",
        name: "莱芜市",
      },
      {
        code: "3713",
        name: "临沂市",
      },
      {
        code: "3714",
        name: "德州市",
      },
      {
        code: "3715",
        name: "聊城市",
      },
      {
        code: "3716",
        name: "滨州市",
      },
      {
        code: "3717",
        name: "菏泽市",
      },
    ],
  },
  {
    code: "41",
    name: "河南省",
    children: [
      {
        code: "4101",
        name: "郑州市",
      },
      {
        code: "4102",
        name: "开封市",
      },
      {
        code: "4103",
        name: "洛阳市",
      },
      {
        code: "4104",
        name: "平顶山市",
      },
      {
        code: "4105",
        name: "安阳市",
      },
      {
        code: "4106",
        name: "鹤壁市",
      },
      {
        code: "4107",
        name: "新乡市",
      },
      {
        code: "4108",
        name: "焦作市",
      },
      {
        code: "4109",
        name: "濮阳市",
      },
      {
        code: "4110",
        name: "许昌市",
      },
      {
        code: "4111",
        name: "漯河市",
      },
      {
        code: "4112",
        name: "三门峡市",
      },
      {
        code: "4113",
        name: "南阳市",
      },
      {
        code: "4114",
        name: "商丘市",
      },
      {
        code: "4115",
        name: "信阳市",
      },
      {
        code: "4116",
        name: "周口市",
      },
      {
        code: "4117",
        name: "驻马店市",
      },
      {
        code: "419001",
        name: "济源市",
      },
    ],
  },
  {
    code: "42",
    name: "湖北省",
    children: [
      {
        code: "4201",
        name: "武汉市",
      },
      {
        code: "4202",
        name: "黄石市",
      },
      {
        code: "4203",
        name: "十堰市",
      },
      {
        code: "4205",
        name: "宜昌市",
      },
      {
        code: "4206",
        name: "襄阳市",
      },
      {
        code: "4207",
        name: "鄂州市",
      },
      {
        code: "4208",
        name: "荆门市",
      },
      {
        code: "4209",
        name: "孝感市",
      },
      {
        code: "4210",
        name: "荆州市",
      },
      {
        code: "4211",
        name: "黄冈市",
      },
      {
        code: "4212",
        name: "咸宁市",
      },
      {
        code: "4213",
        name: "随州市",
      },
      {
        code: "4228",
        name: "恩施土家族苗族自治州",
      },
      {
        code: "429004",
        name: "仙桃市",
      },
      {
        code: "429005",
        name: "潜江市",
      },
      {
        code: "429006",
        name: "天门市",
      },
      {
        code: "429021",
        name: "神农架林区",
      },
    ],
  },
  {
    code: "43",
    name: "湖南省",
    children: [
      {
        code: "4301",
        name: "长沙市",
      },
      {
        code: "4302",
        name: "株洲市",
      },
      {
        code: "4303",
        name: "湘潭市",
      },
      {
        code: "4304",
        name: "衡阳市",
      },
      {
        code: "4305",
        name: "邵阳市",
      },
      {
        code: "4306",
        name: "岳阳市",
      },
      {
        code: "4307",
        name: "常德市",
      },
      {
        code: "4308",
        name: "张家界市",
      },
      {
        code: "4309",
        name: "益阳市",
      },
      {
        code: "4310",
        name: "郴州市",
      },
      {
        code: "4311",
        name: "永州市",
      },
      {
        code: "4312",
        name: "怀化市",
      },
      {
        code: "4313",
        name: "娄底市",
      },
      {
        code: "4331",
        name: "湘西土家族苗族自治州",
      },
    ],
  },
  {
    code: "44",
    name: "广东省",
    children: [
      {
        code: "4401",
        name: "广州市",
      },
      {
        code: "4402",
        name: "韶关市",
      },
      {
        code: "4403",
        name: "深圳市",
      },
      {
        code: "4404",
        name: "珠海市",
      },
      {
        code: "4405",
        name: "汕头市",
      },
      {
        code: "4406",
        name: "佛山市",
      },
      {
        code: "4407",
        name: "江门市",
      },
      {
        code: "4408",
        name: "湛江市",
      },
      {
        code: "4409",
        name: "茂名市",
      },
      {
        code: "4412",
        name: "肇庆市",
      },
      {
        code: "4413",
        name: "惠州市",
      },
      {
        code: "4414",
        name: "梅州市",
      },
      {
        code: "4415",
        name: "汕尾市",
      },
      {
        code: "4416",
        name: "河源市",
      },
      {
        code: "4417",
        name: "阳江市",
      },
      {
        code: "4418",
        name: "清远市",
      },
      {
        code: "4419",
        name: "东莞市",
      },
      {
        code: "4420",
        name: "中山市",
      },
      {
        code: "4451",
        name: "潮州市",
      },
      {
        code: "4452",
        name: "揭阳市",
      },
      {
        code: "4453",
        name: "云浮市",
      },
    ],
  },
  {
    code: "45",
    name: "广西壮族自治区",
    children: [
      {
        code: "4501",
        name: "南宁市",
      },
      {
        code: "4502",
        name: "柳州市",
      },
      {
        code: "4503",
        name: "桂林市",
      },
      {
        code: "4504",
        name: "梧州市",
      },
      {
        code: "4505",
        name: "北海市",
      },
      {
        code: "4506",
        name: "防城港市",
      },
      {
        code: "4507",
        name: "钦州市",
      },
      {
        code: "4508",
        name: "贵港市",
      },
      {
        code: "4509",
        name: "玉林市",
      },
      {
        code: "4510",
        name: "百色市",
      },
      {
        code: "4511",
        name: "贺州市",
      },
      {
        code: "4512",
        name: "河池市",
      },
      {
        code: "4513",
        name: "来宾市",
      },
      {
        code: "4514",
        name: "崇左市",
      },
    ],
  },
  {
    code: "46",
    name: "海南省",
    children: [
      {
        code: "4601",
        name: "海口市",
      },
      {
        code: "4602",
        name: "三亚市",
      },
      {
        code: "4603",
        name: "三沙市",
      },
      {
        code: "4604",
        name: "儋州市",
      },
      {
        code: "469001",
        name: "五指山市",
      },
      {
        code: "469002",
        name: "琼海市",
      },
      {
        code: "469005",
        name: "文昌市",
      },
      {
        code: "469006",
        name: "万宁市",
      },
      {
        code: "469007",
        name: "东方市",
      },
      {
        code: "469021",
        name: "定安县",
      },
      {
        code: "469022",
        name: "屯昌县",
      },
      {
        code: "469023",
        name: "澄迈县",
      },
      {
        code: "469024",
        name: "临高县",
      },
      {
        code: "469025",
        name: "白沙黎族自治县",
      },
      {
        code: "469026",
        name: "昌江黎族自治县",
      },
      {
        code: "469027",
        name: "乐东黎族自治县",
      },
      {
        code: "469028",
        name: "陵水黎族自治县",
      },
      {
        code: "469029",
        name: "保亭黎族苗族自治县",
      },
      {
        code: "469030",
        name: "琼中黎族苗族自治县",
      },
    ],
  },
  {
    code: "50",
    name: "重庆市",
    children: [
      {
        code: "500101",
        name: "万州区",
      },
      {
        code: "500102",
        name: "涪陵区",
      },
      {
        code: "500103",
        name: "渝中区",
      },
      {
        code: "500104",
        name: "大渡口区",
      },
      {
        code: "500105",
        name: "江北区",
      },
      {
        code: "500106",
        name: "沙坪坝区",
      },
      {
        code: "500107",
        name: "九龙坡区",
      },
      {
        code: "500108",
        name: "南岸区",
      },
      {
        code: "500109",
        name: "北碚区",
      },
      {
        code: "500110",
        name: "綦江区",
      },
      {
        code: "500111",
        name: "大足区",
      },
      {
        code: "500112",
        name: "渝北区",
      },
      {
        code: "500113",
        name: "巴南区",
      },
      {
        code: "500114",
        name: "黔江区",
      },
      {
        code: "500115",
        name: "长寿区",
      },
      {
        code: "500116",
        name: "江津区",
      },
      {
        code: "500117",
        name: "合川区",
      },
      {
        code: "500118",
        name: "永川区",
      },
      {
        code: "500119",
        name: "南川区",
      },
      {
        code: "500120",
        name: "璧山区",
      },
      {
        code: "500151",
        name: "铜梁区",
      },
      {
        code: "500152",
        name: "潼南区",
      },
      {
        code: "500153",
        name: "荣昌区",
      },
      {
        code: "500154",
        name: "开州区",
      },
      {
        code: "500155",
        name: "梁平区",
      },
      {
        code: "500156",
        name: "武隆区",
      },
      {
        code: "500229",
        name: "城口县",
      },
      {
        code: "500230",
        name: "丰都县",
      },
      {
        code: "500231",
        name: "垫江县",
      },
      {
        code: "500233",
        name: "忠县",
      },
      {
        code: "500235",
        name: "云阳县",
      },
      {
        code: "500236",
        name: "奉节县",
      },
      {
        code: "500237",
        name: "巫山县",
      },
      {
        code: "500238",
        name: "巫溪县",
      },
      {
        code: "500240",
        name: "石柱土家族自治县",
      },
      {
        code: "500241",
        name: "秀山土家族苗族自治县",
      },
      {
        code: "500242",
        name: "酉阳土家族苗族自治县",
      },
      {
        code: "500243",
        name: "彭水苗族土家族自治县",
      },
    ],
  },
  {
    code: "51",
    name: "四川省",
    children: [
      {
        code: "5101",
        name: "成都市",
      },
      {
        code: "5103",
        name: "自贡市",
      },
      {
        code: "5104",
        name: "攀枝花市",
      },
      {
        code: "5105",
        name: "泸州市",
      },
      {
        code: "5106",
        name: "德阳市",
      },
      {
        code: "5107",
        name: "绵阳市",
      },
      {
        code: "5108",
        name: "广元市",
      },
      {
        code: "5109",
        name: "遂宁市",
      },
      {
        code: "5110",
        name: "内江市",
      },
      {
        code: "5111",
        name: "乐山市",
      },
      {
        code: "5113",
        name: "南充市",
      },
      {
        code: "5114",
        name: "眉山市",
      },
      {
        code: "5115",
        name: "宜宾市",
      },
      {
        code: "5116",
        name: "广安市",
      },
      {
        code: "5117",
        name: "达州市",
      },
      {
        code: "5118",
        name: "雅安市",
      },
      {
        code: "5119",
        name: "巴中市",
      },
      {
        code: "5120",
        name: "资阳市",
      },
      {
        code: "5132",
        name: "阿坝藏族羌族自治州",
      },
      {
        code: "5133",
        name: "甘孜藏族自治州",
      },
      {
        code: "5134",
        name: "凉山彝族自治州",
      },
    ],
  },
  {
    code: "52",
    name: "贵州省",
    children: [
      {
        code: "5201",
        name: "贵阳市",
      },
      {
        code: "5202",
        name: "六盘水市",
      },
      {
        code: "5203",
        name: "遵义市",
      },
      {
        code: "5204",
        name: "安顺市",
      },
      {
        code: "5205",
        name: "毕节市",
      },
      {
        code: "5206",
        name: "铜仁市",
      },
      {
        code: "5223",
        name: "黔西南布依族苗族自治州",
      },
      {
        code: "5226",
        name: "黔东南苗族侗族自治州",
      },
      {
        code: "5227",
        name: "黔南布依族苗族自治州",
      },
    ],
  },
  {
    code: "53",
    name: "云南省",
    children: [
      {
        code: "5301",
        name: "昆明市",
      },
      {
        code: "5303",
        name: "曲靖市",
      },
      {
        code: "5304",
        name: "玉溪市",
      },
      {
        code: "5305",
        name: "保山市",
      },
      {
        code: "5306",
        name: "昭通市",
      },
      {
        code: "5307",
        name: "丽江市",
      },
      {
        code: "5308",
        name: "普洱市",
      },
      {
        code: "5309",
        name: "临沧市",
      },
      {
        code: "5323",
        name: "楚雄彝族自治州",
      },
      {
        code: "5325",
        name: "红河哈尼族彝族自治州",
      },
      {
        code: "5326",
        name: "文山壮族苗族自治州",
      },
      {
        code: "5328",
        name: "西双版纳傣族自治州",
      },
      {
        code: "5329",
        name: "大理白族自治州",
      },
      {
        code: "5331",
        name: "德宏傣族景颇族自治州",
      },
      {
        code: "5333",
        name: "怒江傈僳族自治州",
      },
      {
        code: "5334",
        name: "迪庆藏族自治州",
      },
    ],
  },
  {
    code: "54",
    name: "西藏自治区",
    children: [
      {
        code: "5401",
        name: "拉萨市",
      },
      {
        code: "5402",
        name: "日喀则市",
      },
      {
        code: "5403",
        name: "昌都市",
      },
      {
        code: "5404",
        name: "林芝市",
      },
      {
        code: "5405",
        name: "山南市",
      },
      {
        code: "5406",
        name: "那曲市",
      },
      {
        code: "5425",
        name: "阿里地区",
      },
    ],
  },
  {
    code: "61",
    name: "陕西省",
    children: [
      {
        code: "6101",
        name: "西安市",
      },
      {
        code: "6102",
        name: "铜川市",
      },
      {
        code: "6103",
        name: "宝鸡市",
      },
      {
        code: "6104",
        name: "咸阳市",
      },
      {
        code: "6105",
        name: "渭南市",
      },
      {
        code: "6106",
        name: "延安市",
      },
      {
        code: "6107",
        name: "汉中市",
      },
      {
        code: "6108",
        name: "榆林市",
      },
      {
        code: "6109",
        name: "安康市",
      },
      {
        code: "6110",
        name: "商洛市",
      },
    ],
  },
  {
    code: "62",
    name: "甘肃省",
    children: [
      {
        code: "6201",
        name: "兰州市",
      },
      {
        code: "6202",
        name: "嘉峪关市",
      },
      {
        code: "6203",
        name: "金昌市",
      },
      {
        code: "6204",
        name: "白银市",
      },
      {
        code: "6205",
        name: "天水市",
      },
      {
        code: "6206",
        name: "武威市",
      },
      {
        code: "6207",
        name: "张掖市",
      },
      {
        code: "6208",
        name: "平凉市",
      },
      {
        code: "6209",
        name: "酒泉市",
      },
      {
        code: "6210",
        name: "庆阳市",
      },
      {
        code: "6211",
        name: "定西市",
      },
      {
        code: "6212",
        name: "陇南市",
      },
      {
        code: "6229",
        name: "临夏回族自治州",
      },
      {
        code: "6230",
        name: "甘南藏族自治州",
      },
    ],
  },
  {
    code: "63",
    name: "青海省",
    children: [
      {
        code: "6301",
        name: "西宁市",
      },
      {
        code: "6302",
        name: "海东市",
      },
      {
        code: "6322",
        name: "海北藏族自治州",
      },
      {
        code: "6323",
        name: "黄南藏族自治州",
      },
      {
        code: "6325",
        name: "海南藏族自治州",
      },
      {
        code: "6326",
        name: "果洛藏族自治州",
      },
      {
        code: "6327",
        name: "玉树藏族自治州",
      },
      {
        code: "6328",
        name: "海西蒙古族藏族自治州",
      },
    ],
  },
  {
    code: "64",
    name: "宁夏回族自治区",
    children: [
      {
        code: "6401",
        name: "银川市",
      },
      {
        code: "6402",
        name: "石嘴山市",
      },
      {
        code: "6403",
        name: "吴忠市",
      },
      {
        code: "6404",
        name: "固原市",
      },
      {
        code: "6405",
        name: "中卫市",
      },
    ],
  },
  {
    code: "65",
    name: "新疆维吾尔自治区",
    children: [
      {
        code: "6501",
        name: "乌鲁木齐市",
      },
      {
        code: "6502",
        name: "克拉玛依市",
      },
      {
        code: "6504",
        name: "吐鲁番市",
      },
      {
        code: "6505",
        name: "哈密市",
      },
      {
        code: "6523",
        name: "昌吉回族自治州",
      },
      {
        code: "6527",
        name: "博尔塔拉蒙古自治州",
      },
      {
        code: "6528",
        name: "巴音郭楞蒙古自治州",
      },
      {
        code: "6529",
        name: "阿克苏地区",
      },
      {
        code: "6530",
        name: "克孜勒苏柯尔克孜自治州",
      },
      {
        code: "6531",
        name: "喀什地区",
      },
      {
        code: "6532",
        name: "和田地区",
      },
      {
        code: "6540",
        name: "伊犁哈萨克自治州",
      },
      {
        code: "6542",
        name: "塔城地区",
      },
      {
        code: "6543",
        name: "阿勒泰地区",
      },
      {
        code: "659001",
        name: "石河子市",
      },
      {
        code: "659002",
        name: "阿拉尔市",
      },
      {
        code: "659003",
        name: "图木舒克市",
      },
      {
        code: "659004",
        name: "五家渠市",
      },
      {
        code: "659006",
        name: "铁门关市",
      },
    ],
  },
]
let regionObj = {
  db: {region: "东北", children : ["黑龙江省", "吉林省", "辽宁省"]},
  hb: {region: "华北", children : ["北京市", "天津市", "河北省", "山西省", "内蒙古自治区"]},
  hz: {region: "华中", children : ["河南省", "湖北省", "湖南省"]},
  hd: {region: "华东", children : ["山东省","江苏省","安徽省","上海市","浙江省","江西省","福建省","台湾省"]},
  hn: {region: "华南", children : ["广东省","广西壮族自治区","海南省","香港","澳门"]},
  xb: {region: "西北", children : ["陕西省","甘肃省","宁夏回族自治区","青海省","新疆维吾尔自治区"]},
  xn: {region: "西南", children : ["四川省","贵州省","云南省","重庆市","西藏自治区"]}
}

export default {
  name: "Url",
  components: {
    baseDrawer
  },
  data() {
    return {
      isValidUrl: true,
      isShow: false,
      isEdit: false,
      title: "",
      searchFormData: {
        name: "",
        url: "",
        enabled: ""
      },
      columns: [
        {
          type: 'expand',
          width: 50,
          render: (h, params) => {
            return h(tableExpand, {
              props: {
                row: params.row
              }
            })
          }
        },
        {
          title: 'ID',
          key: 'id',
        },
        {
          title: '名称',
          key: 'name'
        },
        {
          title: 'URL',
          key: 'url'
        },
        {
          title: '监测频率(s)',
          key: 'interval'
        },
        {
          title: '监测大小(KB)',
          key: 'sizeDownload'
        },
        {
          title: '状 态',
          key: 'enabled',
          width: 88,
          sortable: true,
          render: (h, {row}) => {
            let statusColor = {
              "0": ['暂停', '#ed4014'],
              "1": ['开启', '#19be6b']
            }
            return h('Badge',{
              props: {
                color: statusColor[row.enabled][1],
                text:  statusColor[row.enabled][0]
              },
            });
          }
        },
        {
          title: '更新时间',
          key: 'updated_at',
          width: 200,
        },
        {
          title: '创建时间',
          key: 'created_at',
          width: 200,
        },
        {
          title: '操作人',
          key: 'last_operator'
        },
        {
          title: '添加人',
          key: 'creator'
        },
        {
          title: '操 作',
          align: 'left',
          width: 145,
          fixed: 'right',
          slot: 'action',
        }
      ],
      datas: [],
      moniSrcColumns: [
        {
          title: "区域",
          key: "region"
        },
        {
          title: "省份",
          key: "province"
        },
        {
          title: "运营商",
          key: "isp"
        },
        // {
        //   title: "样本数",
        //   key: "sampleSize"
        // },
        {
          title: "监测设备",
          key: "moniHost"
        },
        {
          title: '操 作',
          align: 'left',
          width: 80,
          slot: 'action',
        }
      ],
      formData: {
        name: '',
        url: "",
        interval: 300,
        sizeDownload: 256,
        connectTimeout: 5,
        totalTimeout: 10,
        maxNodes: 0,
        perNodes: 0,
        execTimes: 0,
        expectCode: "206",
        resolveAddr:"",
        specNodeList: "",
        lineType: 0,
        ipType: 2,
        enabled: 1,
        host: "",
        requestHeader: "",
        monitorDetail: [],
        description: ""
      },
      moniSrc: {
        region: "全部",
        province: ["全部"],
        isp: ["全部"],
        moniHost: "",
        sampleSize: 1
      }
    }
  },
  methods: {
    reset() {
      this.formData = {
        name: "",
        url: "",
        interval: 300,
        sizeDownload: 256,
        connectTimeout: 5,
        totalTimeout: 10,
        maxNodes: 0,
        perNodes: 1,
        execTimes: 0,
        expectCode: "206",
        resolveAddr: "",
        specNodeList: "",
        lineType: 0,
        ipType: 2,
        enabled: 1,
        host: "",
        requestHeader: "",
        monitorDetail: [],
        description: ""
      }
    },
    search() {
      const searchData = this.$_.pickBy(this.searchFormData, function(value, key) {
        return value !== "" && value !== -1
      });

      get("/api/v1/httpdetect", searchData)
          .then((resp)=>{
            if (resp.code !== 0) {
              Notice.error("获取操作", resp.msg)
            } else {
              let data = resp.data
              data.forEach((item)=>{
                item.monitorDetail = JSON.parse(item.monitorDetail)
              })
              this.datas = data
            }
          })
          .catch((e)=>{
            console.log(e)
            return e
          })
    },
    getHttpDetects(){
      get("/api/v1/httpdetect")
      .then((resp)=>{
        if (resp.code !== 0) {
          Notice.error("获取操作", resp.msg)
        } else {
          let data = resp.data
          data.forEach((item)=>{
            item.monitorDetail = JSON.parse(item.monitorDetail)
          })
          this.datas = data
        }
      })
      .catch((e)=>{
        console.log(e)
        return e
      })
    },
    create () {
      this.title = "添加任务"
      this.reset()
      this.isShow = true
    },
    handleAddMoniSrc() {
      let moniSrc = this.$_.cloneDeep(this.moniSrc)
      if (moniSrc.region !== "全部"){
        moniSrc.region = regionObj[moniSrc.region].region
        this.formData.monitorDetail.push(moniSrc)
      } else {
        this.formData.monitorDetail.push(moniSrc)
      }
    },
    handleRemoveMoniSrc(row){
      this.formData.monitorDetail.splice(row._index, 1)
    },
    closeDrawer() {
      this.isEdit = false
      this.isShow = false
    },
    handleEdit(row) {
      this.formData = this.$_.cloneDeep(row)
      this.isEdit = true
      this.isShow = true
    },
    handleDel(row) {
      this.$Modal.confirm({
        title: '确认删除以下任务吗？',
        content: '<p>'+ row.name+'</p>',
        onOk: () => {
          del("/api/v1/httpdetect", {"id": [row.id]})
              .then(()=>{
                this.getHttpDetects();
                Notice.success("删除操作", "删除成功")
              }).catch((e)=>{
                return e
              })
        }
      });
    },
    submit() {
      let {connectTimeout, enabled, host, id,
        interval, totalTimeout, maxNodes, perNodes,
        execTimes, expectCode, resolveAddr, specNodeList,
        lineType, ipType, requestHeader, monitorDetail,
        name, sizeDownload, url, description} = this.$_.cloneDeep(this.formData)
      let postData = {connectTimeout, enabled, host, id,
        interval,totalTimeout, maxNodes, perNodes,
        execTimes, expectCode, specNodeList, lineType,
        ipType, resolveAddr, requestHeader, monitorDetail,
        name, sizeDownload, url, description}

      if (this.isValidUrl === false) {
        Notice.error("提交操作", "url格式不正确")
        return
      }
      postData.monitorDetail = JSON.stringify(postData.monitorDetail)
      if (this.isEdit) {
        put("/api/v1/httpdetect", postData)
            .then((resp) => {
              if (resp.code === 0) {
                Notice.success("更新操作", "更新成功")
              } else {
                Notice.error("更新操作", resp.msg)
              }
              this.getHttpDetects()
            }).catch((e) => {
              return e
            })
        this.closeDrawer()
      } else {
        post("/api/v1/httpdetect", postData)
            .then((resp)=>{
              if (resp.code === 0) {
                Notice.success("提交操作", "添加成功")
              } else {
                Notice.error("提交操作", resp.msg)
              }
              this.getHttpDetects()
            })
            .catch((e)=>{
              return e
            })
        this.closeDrawer()
      }
    },
    CheckUrl() {
      if (this.formData.url.length === 0) {
        this.isValidUrl = true
      }

      // 检查URL是否以"http://"或"https://"开头
      const startsWithHttp = /^https?:\/\//.test(this.formData.url);
      // 检查URL是否包含空格或制表符
      const containsInvalidChars = /\s/.test(this.formData.url);

      this.isValidUrl =  startsWithHttp && !containsInvalidChars && this.formData.url !== "http://" && this.formData.url !== "https://"
    }
  },
  computed: {
    getProvince() {
      return GEO.filter((item) => {
        this.moniSrc.province = ["全部"]
        if (this.moniSrc.region === "全部" || this.moniSrc.region === "") {
          this.moniSrc.province = ["全部"]
          return
        }
          return regionObj[this.moniSrc.region].children.indexOf(item.name) > -1
        })
    },
  },
  created() {
    this.getHttpDetects()
  }
}
</script>

<style scoped>
/deep/ .searchForm .ivu-form-item {
  margin-bottom: 0;
}

.invalid-url {
  border: 1px solid red;
}

.tooltip-base-box .icon-item {
  width: 10px;
  margin-top: 5px;
}

.error-message {
  color: red;
  margin-top: 5px;
}
</style>